// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

	/** primary **/
	--ion-color-primary: #004080;
	--ion-color-primary-rgb: 0,64,128;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #00356a;
	--ion-color-primary-tint: #0060bf;

	/** secondary **/
	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12,209,232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112,68,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16,220,96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255,206,0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb:  240,65,65;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb:  34,36,40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb:  152,154,162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb:255,255,255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb:  244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** transparent **/
	--ion-color-transparent: transparent;
	--ion-color-transparent-rgb: transparent;
	--ion-color-transparent-contrast: #000000;
	--ion-color-transparent-contrast-rgb: 0,0,0;
	--ion-color-transparent-shade: transparent;
	--ion-color-transparent-tint: transparent;




	/** CUSTOM COLOR **/

}




/** Dark Colors **/
body[data-theme="dark"]{
	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56,128,255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12,209,232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112,68,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16,220,96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255,206,0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb:  240,65,65;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb:  34,36,40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb:  152,154,162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb:255,255,255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb:  244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	
	ion-content{
		--background: #000000 !important;
	}
	ion-card{
		--background: #000000 !important;
	}
	ion-card-content{
		--background: #000000 !important;
	}
	ion-card-title{
		--background: #000000 !important;
	}
	ion-card-header{
		--background: #000000 !important;
	}
	blockquote{
		background: transparent !important;
	}


}


/** iOS Dark Theme **/
.ios body[data-theme="dark"]{
	--ion-background-color: #000000;
	--ion-background-color-rgb: 0,0,0;
	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255,255,255;
	--ion-color-step-50: #0d0d0d;
	--ion-color-step-100: #1a1a1a;
	--ion-color-step-150: #262626;
	--ion-color-step-200: #333333;
	--ion-color-step-250: #404040;
	--ion-color-step-300: #4d4d4d;
	--ion-color-step-350: #595959;
	--ion-color-step-400: #666666;
	--ion-color-step-450: #737373;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #8c8c8c;
	--ion-color-step-600: #999999;
	--ion-color-step-650: #a6a6a6;
	--ion-color-step-700: #b3b3b3;
	--ion-color-step-750: #bfbfbf;
	--ion-color-step-800: #cccccc;
	--ion-color-step-850: #d9d9d9;
	--ion-color-step-900: #e6e6e6;
	--ion-color-step-950: #f2f2f2;
	--ion-toolbar-background: #0d0d0d;
	--ion-item-background: #000000;
}


/** Material Design Dark Theme **/
.md body[data-theme="dark"]{
	--ion-background-color: #121212;
	--ion-background-color-rgb: 18,18,18;
	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255,255,255;
	--ion-border-color: #222222;
	--ion-color-step-50: #1e1e1e;
	--ion-color-step-100: #2a2a2a;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;
	--ion-item-background: #1e1e1e;
	--ion-toolbar-background: #1f1f1f;
	--ion-tab-bar-background: #1f1f1f;
}


@media (prefers-color-scheme: dark) {


	/** Dark Colors **/
	body{
		/** primary **/
		--ion-color-primary: #3880ff;
		--ion-color-primary-rgb: 56,128,255;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255,255,255;
		--ion-color-primary-shade: #3171e0;
		--ion-color-primary-tint: #4c8dff;

		/** secondary **/
		--ion-color-secondary: #0cd1e8;
		--ion-color-secondary-rgb: 12,209,232;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255,255,255;
		--ion-color-secondary-shade: #0bb8cc;
		--ion-color-secondary-tint: #24d6ea;

		/** tertiary **/
		--ion-color-tertiary: #7044ff;
		--ion-color-tertiary-rgb: 112,68,255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255,255,255;
		--ion-color-tertiary-shade: #633ce0;
		--ion-color-tertiary-tint: #7e57ff;
	
		/** success **/
		--ion-color-success: #10dc60;
		--ion-color-success-rgb: 16,220,96;
		--ion-color-success-contrast: #ffffff;
		--ion-color-success-contrast-rgb: 255,255,255;
		--ion-color-success-shade: #0ec254;
		--ion-color-success-tint: #28e070;
	
		/** warning **/
		--ion-color-warning: #ffce00;
		--ion-color-warning-rgb: 255,206,0;
		--ion-color-warning-contrast: #ffffff;
		--ion-color-warning-contrast-rgb: 255,255,255;
		--ion-color-warning-shade: #e0b500;
		--ion-color-warning-tint: #ffd31a;

		/** danger **/
		--ion-color-danger: #f04141;
		--ion-color-danger-rgb:  240,65,65;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255,255,255;
		--ion-color-danger-shade: #d33939;
		--ion-color-danger-tint: #f25454;
	
		/** dark **/
		--ion-color-dark: #222428;
		--ion-color-dark-rgb:  34,36,40;
		--ion-color-dark-contrast: #ffffff;
		--ion-color-dark-contrast-rgb: 255,255,255;
		--ion-color-dark-shade: #1e2023;
		--ion-color-dark-tint: #383a3e;

		/** medium **/
		--ion-color-medium: #989aa2;
		--ion-color-medium-rgb:  152,154,162;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb:255,255,255;
		--ion-color-medium-shade: #86888f;
		--ion-color-medium-tint: #a2a4ab;

		/** light **/
		--ion-color-light: #f4f5f8;
		--ion-color-light-rgb:  244,245,248;
		--ion-color-light-contrast: #000000;
		--ion-color-light-contrast-rgb: 0,0,0;
		--ion-color-light-shade: #d7d8da;
		--ion-color-light-tint: #f5f6f9;

	}


	/** iOS Dark Theme **/
	.ios body{
		--ion-background-color: #000000;
		--ion-background-color-rgb: 0,0,0;
		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255,255,255;
		--ion-color-step-50: #0d0d0d;
		--ion-color-step-100: #1a1a1a;
		--ion-color-step-150: #262626;
		--ion-color-step-200: #333333;
		--ion-color-step-250: #404040;
		--ion-color-step-300: #4d4d4d;
		--ion-color-step-350: #595959;
		--ion-color-step-400: #666666;
		--ion-color-step-450: #737373;
		--ion-color-step-500: #808080;
		--ion-color-step-550: #8c8c8c;
		--ion-color-step-600: #999999;
		--ion-color-step-650: #a6a6a6;
		--ion-color-step-700: #b3b3b3;
		--ion-color-step-750: #bfbfbf;
		--ion-color-step-800: #cccccc;
		--ion-color-step-850: #d9d9d9;
		--ion-color-step-900: #e6e6e6;
		--ion-color-step-950: #f2f2f2;
		--ion-toolbar-background: #0d0d0d;
		--ion-item-background: #000000;
	}


	/** Material Design Dark Theme **/
	.md body{
		--ion-background-color: #121212;
		--ion-background-color-rgb: 18,18,18;
		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255,255,255;
		--ion-border-color: #222222;
		--ion-color-step-50: #1e1e1e;
		--ion-color-step-100: #2a2a2a;
		--ion-color-step-150: #363636;
		--ion-color-step-200: #414141;
		--ion-color-step-250: #4d4d4d;
		--ion-color-step-300: #595959;
		--ion-color-step-350: #656565;
		--ion-color-step-400: #717171;
		--ion-color-step-450: #7d7d7d;
		--ion-color-step-500: #898989;
		--ion-color-step-550: #949494;
		--ion-color-step-600: #a0a0a0;
		--ion-color-step-650: #acacac;
		--ion-color-step-700: #b8b8b8;
		--ion-color-step-750: #c4c4c4;
		--ion-color-step-800: #d0d0d0;
		--ion-color-step-850: #dbdbdb;
		--ion-color-step-900: #e7e7e7;
		--ion-color-step-950: #f3f3f3;
		--ion-item-background: #1e1e1e;
		--ion-toolbar-background: #1f1f1f;
		--ion-tab-bar-background: #1f1f1f;
	}


	ion-content{
		--background: #000000 !important;
	}
	ion-card{
		--background: #000000 !important;
	}
	ion-card-content{
		--background: #000000 !important;
	}
	ion-card-title{
		--background: #000000 !important;
	}
	ion-card-header{
		--background: #000000 !important;
	}
	blockquote{
		background: transparent !important;
	}


}

