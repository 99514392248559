@charset "UTF-8";
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
/** COLOR **/
.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

/** CUSTOM COLOR **/
/** TYPOGRAPHY  **/
:focus {
  outline: none;
}

ion-card-content p {
  margin-top: 0;
  margin-bottom: 0.5em !important;
}
ion-card-content .underline {
  text-decoration: underline !important;
}
ion-card-content .underline {
  text-decoration: underline !important;
}
ion-card-content .italic {
  font-style: italic !important;
}
ion-card-content .visible {
  visibility: visible !important;
}
ion-card-content .strong {
  font-weight: bold !important;
}
ion-card-content blockquote {
  border-left: 3px solid #ddd;
  margin-left: 0;
  padding-left: 1em;
  background: #fdfdfd;
}
ion-card-content blockquote footer {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
ion-card-content blockquote footer:before {
  content: "— ";
}
ion-card-content pre {
  border: 1px solid #ddd;
  background: #eee;
  padding: 12px;
}
ion-card-content table {
  width: 100%;
  border: 1px solid #ddd;
}
ion-card-content table td {
  border: 1px solid #ddd;
  padding: 0.5em;
}
ion-card-content table th {
  border: 1px solid #ddd;
  padding: 0.5em;
}
ion-card-content table.noborder, ion-card-content table.no-border {
  border: 0;
}
ion-card-content table.noborder td, ion-card-content table.no-border td {
  border: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0;
  padding-right: 0;
}
ion-card-content table.noborder th, ion-card-content table.no-border th {
  border: 0;
}
ion-card-content h1, ion-card-content h2, ion-card-content h3, ion-card-content h4, ion-card-content h5, ion-card-content h6 {
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
ion-card-content figure {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
ion-card-content img, ion-card-content iframe {
  max-width: 100% !important;
  height: auto !important;
}
ion-card-content .align-left {
  float: left;
  margin-right: 1em;
}
ion-card-content .align-right {
  float: right;
  margin-left: 1em;
}
ion-card-content .align-center {
  clear: both;
  display: block;
  margin: 0 auto;
}
ion-card-content .text-left {
  text-align: left !important;
}
ion-card-content .text-right {
  text-align: right !important;
}
ion-card-content .text-center {
  text-align: center !important;
}

/** SIDE MENUS **/
/*[app-sidemenu-content]{
	--ion-item-background: transparent !important;
	ion-list{
		padding-top: 0;
		padding-bottom: 0;
	}
}*/
[app-sidemenu-header] {
  background-image: url("assets/images/logo_400w.png");
  height: 100px;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
[app-sidemenu-header] h1 {
  color: white;
  margin-top: 2rem;
  font-size: 3rem;
}
[app-sidemenu-header] span {
  color: white;
}

[app-sidemenu-header] h1 {
  font-size: 2.8rem;
}